import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Field from '../Field/Field';
import CTAButton from '../CTAButton/CTAButton';
import './TuitionInsuranceForm.scss';
import '../../Components/ApplicantApplication/ApplicationCard/application-card.scss';
import Loader from '../Loader/Loader';
import { toast } from 'react-toastify';
import FlashMessage from '../../Components/FlashMessage/Message'
import { formatDateByMoment } from '../../Utility/FormatDate';
import { CreateOptions } from '../../Utility/SelectListLoader';
import dynamic from 'next/dynamic'
import SweetAlert from 'react-bootstrap-sweetalert';
import Alert from '../SVG/Alert';
import { MAIN_CONFIG, US_STATE_LIST, US_STATE_ZIP_CODE_RANGE } from '../../../config/main';
import { parseHtml } from '../../Utility/HelperFunctions';

dynamic(() => Promise.resolve(NoSsr), {
  ssr: false
})
function TuitionInsuranceForm({ setModalState = () => { }, contactData }) {

  const ref = React.useRef(null);

  const initialFormData = {
    first_name: contactData.first_name || '',
    last_name: contactData.last_name || '',
    email: contactData.email || '',
    mailing_street__c: contactData.mailing_street__c || '',
    mailing_city__c: contactData.mailing_city__c || '',
    student_state: contactData.student_state || '',
    mailing_zip_postal_code__c: contactData.mailing_zip_postal_code__c || '',
    school_name: contactData.school_name || '',
    semester_end_date: contactData.semester_end_date || '',
    semester_start_date: contactData.semester_start_date || '',
    school_type: contactData.school_type || '',
    cost: contactData.cost || '',
    date_of_birth__c: contactData.date_of_birth__c || ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const [quoteResponseStatus, setQuoteResponseStatus] = useState(false)
  const [paymentAlertVisible, setPaymentAlertVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedState, setSelectedState] = useState('');
  const [updateAlert, setUpdateAlert] = useState(false);
  const [submitting, setSubmitting] = useState(false)
  const [studentSchoolType, setStudentSchoolType] = useState('')
  const [fieldErrors, setFieldErrors] = useState({});
  let fullName = `${formData.first_name} ${formData.last_name}`
  const currentDate = new Date();
  const semesterStartDate = new Date(formData.semester_start_date);
  const semesterEndDate = new Date(formData.semester_end_date);
  const schoolTypeList = ['2-year', '4-year', 'For Profit']
  const clientId_clientSecret = `${process.env.NEXT_PUBLIC_VERTICAL_INSURANCE_CLIENT_ID}:${process.env.NEXT_PUBLIC_VERTICAL_INSURANCE_CLIENT_SECRET}`

  const handleUpdateSchoolTypeValue = (schoolType) => {
    let result = schoolType?.value
    switch (result) {
      case '2-year':
        return 'TWO_YEAR';
      case '4-year':
        return 'FOUR_YEAR';
      case 'For Profit':
        return 'FOR_PROFIT';
      default:
        return '';
    }
  };
  
  const handleClose = () => {
    setModalState({
      visible: false
    })
  }

  useEffect(() => {
    checkOfferState()
    if (loading) {
      setTimeout(() => {
        setLoading(false)
      }, 3000)
    }
    if (selectedState === 'DECLINED') {
      getConfirmation()
    }
  }, [loading, selectedState])


  const checkOfferState = () => {
    const insuranceComponent = document.querySelector('tuition-insurance');
    insuranceComponent?.addEventListener('offer-state-change', function (event) {
      setSelectedState(event?.detail?.selectedState)
    });
  }

  const getConfirmation = (e) => {
    const alert = () => (
      <div className="save-changes-confirmation-alert modal-utility confirmation_modal">
        <SweetAlert
          showConfirm={true}
          confirmBtnText="Okay"
          showCancel={true}
          cancelBtnText="Cancel"
          customButtons={
            <div className='action-footer'>
              <button onClick={(e) => setUpdateAlert(false)} className='cta-button CTABlack cta-button__text'>No</button>
              <button onClick={(e) => {
                handleClose();
              }}
                className='cta-button CTAWhite tertiary cta-button__text'>Yes Decline</button>
            </div>
          }
          className=""
        >
          <div style={{ display: 'flex' }}>
            <div>
              <Alert />
            </div>
            <div className='content'>
              <p className='title'>Are you sure you want to decline?</p>
            </div>
          </div>
        </SweetAlert>
      </div>
    );
    setUpdateAlert(alert)
  }

  const showPaymenSuccessAlert = () => {
    setPaymentAlertVisible(true)
    const getAlert = () => (
      <div id='payment-confirmation-alert'>
        <SweetAlert
          custom
          confirmBtnCssClass="cta-button CTAWhite tertiary"
          title='Success!'
          confirmBtnText="Close"
          confirmBtnBsStyle="primary"
          show={true}
          customClass="user-confirmation-alert"
          openAnim="false"
          customIcon={MAIN_CONFIG.STATIC_IMAGE_URL.Success_Icon}
          customButtons={
            <div className='action-footer'>
              <button onClick={(e) => {
                setUpdateAlert(false)
                handleClose()
                setPaymentAlertVisible(false)
              }} className='cta-button CTAWhite tertiary cta-button__text'>Okay</button>
            </div>}
        >
          <p className="user-confirmation-alert__message"> Payment Successful! Please check your email for a copy of your policy.</p>
        </SweetAlert>
      </div>
    );
    setUpdateAlert(getAlert)
  }

  const component = document.querySelector('tuition-insurance');
  let quoteDetails = component?.quote;

  const handleSubmit = async () => {
    setSubmitting(true)
    try {
      const validation = await component.validate();
      if (validation.isValid) {
        const paymentToken = await component.getPaymentToken(fullName, formData.mailing_zip_postal_code__c);
        // Prepare data for the request
        const data = {
          customer: {
            email_address: formData?.email,
            first_name: formData?.first_name,
            last_name: formData?.last_name,
          },
          metadata: {},
          quote_id: quoteDetails?.quote_id,
          payment_method: {
            token: paymentToken,
            save_for_future_use: true
          },
          subscription: false
        };

        // Send the request to the API
        const response = await fetch('https://api.verticalinsure.com/v1/purchase/tuition', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${btoa(clientId_clientSecret)}`
          },
          body: JSON.stringify(data)
        });

        if (!response.ok) {
          toast.error(<FlashMessage type='error' message={response?.message || 'something went wrong'} />)
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        // show payment success popup
        showPaymenSuccessAlert()
      }
    } catch (error) {

      // Handle errors, such as network errors or server errors
      console.error('Error:', error.message);
    }
    setLoading(false);
    setSubmitting(false)
  };

  const handleGetQuote = (e) => {
    e.preventDefault();
    const valid = handleValidation()

    if (semesterStartDate <= currentDate || semesterEndDate <= currentDate) {
      toast.error(<FlashMessage type='error' message='The start and end of the semester must be a future date.' />);
      return; // Exit function early if dates are not in the future
    }

    if(formData?.cost < 40){
      toast.error(<FlashMessage type='error' message='Cost (Tuition, Room & Board) must be greater than or equal to $40.' />);
      return; // Exit function early if the amount less than 40
    }
    // if all required question are filled out the set the quote response success else show toast message
    if (valid) {
      setLoading(true)
      setQuoteResponseStatus(true)
    } else {
      toast.error(<FlashMessage type='error' message='Please fill out all the required fields.' />)
    }
  };

  const getSelectValue = (value) => {
    return value?.value ? value : '';
  };

  const onChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    // Clear field error if value inserted
    setFieldErrors({ ...fieldErrors, [name]: '' });
  };


  const handleShowError = (id) => {
    if (Object.keys(fieldErrors)?.length > 0) {
      for (const key in fieldErrors) {
        if (key === id) {
          return fieldErrors[key];
        }
      }
    }
    return null;
  };

  const handleValidation = () => {
    let isValid = true;
    const errors = {};

    // Check if all required fields are filled out
    Object.keys(formData).forEach(key => {
      if (!formData[key]) {
        isValid = false;
        errors[key] = 'This field is required.';
      }
    });
  
    // Validate zip code based on selected state
    const state = formData?.student_state?.value; // Assuming student_state contains the selected state object
    const zipCode = formData?.mailing_zip_postal_code__c;
    if (state && zipCode) {
      const zipRange = getZipRange(state);
      if (!isValidZipCode(zipCode, zipRange)) {
        isValid = false;
        errors['mailing_zip_postal_code__c'] = 'Invalid zip code for the selected state.';
      }
    }
  
    setFieldErrors(errors); // Set errors for each field

    return isValid;
  };

// Function to get the zip code range based on the selected state
const getZipRange = (state) => {
  // Check if the state is in the lookup table
  if (state && US_STATE_ZIP_CODE_RANGE[state]) {
    return US_STATE_ZIP_CODE_RANGE[state];
  } else {
    // Return null
    return null;
  }
};

// Function to validate if the zip code falls within the range
const isValidZipCode = (zipCode, zipRange) => {
  // Convert zip code and range from strings to numbers
  const zip = parseInt(zipCode);
  const min = parseInt(zipRange.min);
  const max = parseInt(zipRange.max);

  // Check if the zip code is within the range
  return zip >= min && zip <= max;
};
const renderLabelWithAsterisk = (label) => parseHtml(`${label} <span style="color:red">*</span>`)

  return (
    !quoteResponseStatus ? (
      <div className="tuition-form">
        <div className="tuition-form__head_container">
        <div className='tuition_main_container'>
        <div className='tution_head_container'>
          <div>
            <div className='first-heading-top'>Protect your tuition,</div>
            <div className="H2DesktopGreen event-head">protect your future.</div>
            <div className='BodyDefaultRegularBlack sub-heading-top'>The cost of tuition, room and board, books, school supplies, daily living expenses... it adds up. What happens if you have to unexpectedly withdraw for a semester? The average school refund policy will only reimburse students for tuition if they withdraw within a brief window - usually only a couple of weeks after classes start.
            </div>
          </div>
          <div className="Tuition-image">
           <img src={'/static/images/tution-insure-offer.png'} alt="Tuition" /></div>
          </div>
        </div>
        <div className='tution_head_description'>
         <br />
         <div className='BodyDefaultRegularBlack sub-heading-top'>
           <p>Tuition Protection will refund you for your tuition throughout the entire semester, and even includes room and board, textbooks, and other related expenses. Covered reasons for reimbursement include: accidents, injuries, chronic illnesses,
              and other medical conditions. See the full list, <a href={MAIN_CONFIG.STATIC_IMAGE_URL.Vi_Learn_more} target="_blank" ><u>here.</u></a></p><br />
           <p>Fill out the form below to view your custom quote.</p></div>
        </div>
        </div>
        <hr className="heading-divider_solid" />
        <form className="tuition-form-container" onSubmit={handleGetQuote}>
          <h1 className="H4DesktopGreen section-heading">Student Information</h1>
          <div className='student-information'>
            <div className='contact-info-section'>
              <Field
                className="field-group-tuition-insurence primary-color-field"
                label={renderLabelWithAsterisk("First Name")}
                placeholder="First Name"
                type="text"
                required={true}
                value={formData.first_name}
                handleChange={(val) => onChange('first_name', val)}
                errorMsg={handleShowError('first_name')}
              />

              <Field
                className="field-group-tuition-insurence secondary-color-field"
                label={renderLabelWithAsterisk("Last Name")}
                placeholder="Last Name"
                type="text"
                required={true}
                value={formData.last_name}
                handleChange={(val) => onChange('last_name', val)}
                errorMsg={handleShowError('last_name')}
              />

              <Field
                className="field-group-tuition-insurence"
                label={renderLabelWithAsterisk("Email")}
                id="email"
                name="email"
                type="email"
                required={true}
                value={formData.email}
                handleChange={(val) => onChange('email', val)}
                errorMsg={handleShowError('email')}
              />

              <Field
                className="field-group-tuition-insurence single-field-row"
                label={renderLabelWithAsterisk("Date Of Birth")}
                placeholder="Date Of Birth"
                type="Date"
                required={true}
                value={formData.date_of_birth__c}
                handleChange={(val) => onChange('date_of_birth__c', val)}
                errorMsg={handleShowError('date_of_birth__c')}
              />
            </div>

            <div className='address-info-section'>
              <Field
                className="field-group-tuition-insurence"
                label={renderLabelWithAsterisk("Street Address")}
                placeholder='Street Address'
                id="Street_Address"
                name="StreetAddress"
                type="text"
                required={true}
                value={formData.mailing_street__c}
                handleChange={(val) => onChange('mailing_street__c', val)}
                errorMsg={handleShowError('mailing_street__c')}
              />

              <Field
                className="field-group-tuition-insurence"
                label={renderLabelWithAsterisk("City")}
                placeholder="City"
                type="text"
                required={true}
                value={formData.mailing_city__c}
                handleChange={(val) => onChange('mailing_city__c', val)}
                errorMsg={handleShowError('mailing_city__c')}
              />

              <Field
                className="field-group-tuition-insurence"
                label={renderLabelWithAsterisk("State")}
                id='student_state'
                placeholder="State"
                type="select"
                required={true}
                options={CreateOptions(US_STATE_LIST)}
                value={getSelectValue(formData.student_state)}
                handleChange={(val) => onChange('student_state', val)}
                errorMsg={handleShowError('student_state')}
              />

              <Field
                className="field-group-tuition-insurence single-field-row"
                label={renderLabelWithAsterisk("Zip Code")}
                placeholder="Zip Code"
                type="number"
                required={true}
                value={formData.mailing_zip_postal_code__c}
                handleChange={(val) => onChange('mailing_zip_postal_code__c', val)}
                errorMsg={handleShowError('mailing_zip_postal_code__c')}
              />
            </div>
          </div>

          <h1 className="H4DesktopGreen section-heading">School Information</h1>
          <div className='school-information'>
            <div className='school-info-section'>
              <Field
                className="field-group-tuition-insurence"
                label={renderLabelWithAsterisk("School/Institution Name")}
                placeholder="School/Institution Name"
                type="text"
                required={true}
                value={formData.school_name}
                handleChange={(val) => onChange('school_name', val)}
                errorMsg={handleShowError('school_name')}
              />

              <Field
                className="field-group-tuition-insurence"
                label={renderLabelWithAsterisk("School/Institution Type")}
                placeholder="School/Institution Type"
                type="select"
                required={true}
                options={CreateOptions(schoolTypeList)}
                value={getSelectValue(formData.school_type)}
                // handleChange={(val) => onChange('school_type', val)}
                handleChange={(val) => {
                  onChange('school_type', val);
                  setStudentSchoolType(handleUpdateSchoolTypeValue(val));
                }}
                errorMsg={handleShowError('school_type')}
              />

              <Field
                className="field-group-tuition-insurence"
                label={renderLabelWithAsterisk("Cost (Tuition, Room & Board)")}
                placeholder="Cost (Tuition, Room & Board)"
                type="money"
                required={true}
                value={formData.cost}
                minNumber={40}
                handleChange={(val) => onChange('cost', val)}
                errorMsg={handleShowError('cost')}
              />
            </div>

            <div className='semester-info-section'>
              <Field
                className="field-group-tuition-insurence"
                label={renderLabelWithAsterisk("Semester Start Date")}
                placeholder="Semester Start Date"
                type="date"
                required={true}
                value={formData.semester_start_date}
                isFutureDatePicker={new Date()}
                handleChange={(val) => onChange('semester_start_date', val)}
                errorMsg={handleShowError('semester_start_date')}
              />

              <Field
                className="field-group-tuition-insurence"
                label={renderLabelWithAsterisk("Semester End Date")}
                name="Semester End Date"
                type="date"
                required={true}
                value={formData.semester_end_date}
                isFutureDatePicker={new Date()}
                handleChange={(val) => onChange('semester_end_date', val)}
                errorMsg={handleShowError('semester_end_date')}
              />
            </div>
          </div>

          <div className="tuition-form-footer-container">
            <CTAButton className="cancel-button" type="inverse" outline onClick={handleClose}>
              Cancel
            </CTAButton>
            <CTAButton className="submit-button" type="tertiary" buttonType="submit" onClick={handleGetQuote}
            >
              Get Quote
            </CTAButton>
          </div>
        </form>
      </div>
    )
      : (
        <>
          {updateAlert}
          <Loader loading={loading || submitting} />
          <div className='vertical-insurance-main-content-page'>
            <div className="task-head_container vi-modal-cross" >
              <button className="event-head H6DesktopGrey cancel-icon" onClick={handleClose}>X</button>
            </div>
            <tuition-insurance
              ref={ref}
              client-id={process.env.NEXT_PUBLIC_VERTICAL_INSURANCE_CLIENT_ID}
              semester-start-date={formatDateByMoment(formData?.semester_start_date, 'YYYY-MM-DD')}
              semester-end-date={formatDateByMoment(formData?.semester_end_date, 'YYYY-MM-DD')}
              tuition-amount={(parseFloat(formData.cost.replace(/,/g, ''))* 100).toFixed(0)}
              university-name={formData?.school_name}
              institution-type={studentSchoolType}
              student-name={fullName}
              student-email={formData?.email}
              student-birth-date={formatDateByMoment(formData?.date_of_birth__c, 'YYYY-MM-DD')}
              student-zip-code={formData?.mailing_zip_postal_code__c}
              student-state={formData?.student_state?.value}
              student-country="US"
              include-payment-element
            ></tuition-insurance>
            <div className='payment-footer-container'>
              {selectedState === 'ACCEPTED' && <CTAButton className="submit-button" type="tertiary" buttonType="submit" onClick={handleSubmit} disabled={submitting || paymentAlertVisible}>
                Purchase
              </CTAButton>}
            </div>
          </div>
        </>
      )
  );
}

export default TuitionInsuranceForm;

// handle propTypes
TuitionInsuranceForm.propTypes = {
  setModalState: PropTypes.func,
  contactData: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    mailing_street__c: PropTypes.string,
    mailing_city__c: PropTypes.string,
    student_state: PropTypes.string,
    mailing_zip_postal_code__c: PropTypes.string,
    school_name: PropTypes.string,
    semester_end_date: PropTypes.string,
    semester_start_date: PropTypes.string,
    school_type: PropTypes.string,
    cost: PropTypes.number,
    date_of_birth__c: PropTypes.string
  }),
};
